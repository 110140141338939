<template>
  <div>
    <div class="page-body">
      <loading
        :active="loader"
        :is-full-page="true"
        :opacity="0.9"
        color="#ff6b00"
      />
      <TitleBar :title="$t('Payins')" />
      <v-container>
        <v-row class="mt-3">
          <v-col cols="12">
            <v-card
              class="pa-2 main-card v-card-tile h-100"
              rounded="lg"
              elevation="0"
            >
              <v-row no-gutters>
                <v-icon>mdi-account-circle</v-icon
                ><label class="pt-1 ps-1 m-0 text-color">{{
                  $t("accountSumary")
                }}</label>
                <v-icon
                  class="ml-auto"
                  title="Balance History"
                  @click="$router.push('/balance')"
                  >mdi-launch
                </v-icon>
              </v-row>
              <v-row class="d-flex justify-space-between pa-4">
                <v-col cols="12" md="4">
                  <v-card class="mt-2 border" elevation="0">
                    <div class="d-flex">
                      <v-sheet
                        class="v-sheet--offset ms-4 pa-4 mx-auto d-flex justify-center"
                        color="cyan"
                        elevation="0"
                        width="70px"
                        rounded
                        height="70px"
                      >
                        <v-icon color="white" large> mdi-cash-multiple </v-icon>
                      </v-sheet>
                      <div class="me-4 mt-2">
                        <div class="text-right text-color font-size">
                          {{ $t("Balance") }}
                        </div>
                        <div class="text-right text-h5 font-weight-bold">
                          <a
                            class="primary--text"
                            @click="$router.push('/balance')"
                            link
                            >$
                            {{ (balance && balance.available_balance) || 0 }}</a
                          >
                        </div>
                      </div>
                    </div>
                  </v-card>
                </v-col>
                <v-col cols="12" md="4">
                  <v-card class="mt-2 border" elevation="0">
                    <div class="d-flex">
                      <v-sheet
                        class="v-sheet--offset ms-4 pa-4 mx-auto d-flex justify-center"
                        color="info"
                        elevation="0"
                        rounded
                        width="70px"
                        height="70px"
                      >
                        <v-icon color="white" large>
                          mdi-account-arrow-left
                        </v-icon>
                      </v-sheet>
                      <div class="me-4 mt-2">
                        <div class="text-right text-color font-size">
                          {{ $t("Incoming") }}
                        </div>
                        <div class="text-right text-h5 font-weight-bold">
                          <a
                            class="primary--text"
                            @click="$router.push('/balance')"
                            link
                            >$
                            {{ (balance && balance.incoming_balance) || 0 }}</a
                          >
                        </div>
                      </div>
                    </div>
                  </v-card>
                </v-col>
                <v-col cols="12" md="4">
                  <v-card class="mt-2 border" elevation="0">
                    <div class="d-flex">
                      <v-sheet
                        class="v-sheet--offset ms-4 pa-2 mx-auto d-flex justify-center"
                        color="warning"
                        elevation="0"
                        width="70px"
                        rounded
                        height="70px"
                      >
                        <v-icon color="white" large>
                          mdi-account-arrow-right
                        </v-icon>
                      </v-sheet>
                      <div class="me-4 mt-2">
                        <div class="text-right text-color font-size">
                          {{ $t("Outgoing") }}
                        </div>
                        <div class="text-right text-h5 font-weight-bold">
                          <a
                            class="primary--text"
                            @click="$router.push('/balance')"
                            link
                            >$
                            {{ (balance && balance.outgoing_balance) || 0 }}</a
                          >
                        </div>
                      </div>
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="recent_txn_scroll">
          <v-col cols="12" class="my-2">
            <loading
              :active="isRecentTrans"
              :is-full-page="false"
              :opacity="0.7"
              color="#ff6b00"
            />

            <v-card class="pt-0" rounded="lg" elevation="0">
              <v-row no-gutters>
                <label class="pt-1 ps-1 m-0 text-color">{{
                  $t("recent_transcations")
                }}</label>
              </v-row>
              <v-data-table
                :headers="headers"
                dense
                :items="recentTransaction"
                hide-default-footer
                class="elevation-0 border"
              >
                <template v-slot:item.status="{ item }">
                  <v-chip
                    v-if="item.status"
                    dense
                    class="text-capitalize"
                    small
                    :color="getColor(item.status)"
                    dark
                  >
                    {{ item.status }}
                  </v-chip>
                </template>
                <template v-slot:item.amount="{ item }">
                  <v-chip
                    dense
                    small
                    outlined
                    :color="item.txn_type === 'I' ? 'success' : 'red'"
                  >
                    <span class="font-weight-bold" :class="returnTextCol(item)">
                      {{ returntxnType(item) }} {{ item.amount }}
                    </span>
                  </v-chip>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="">
          <v-col cols="12" md="6" lg="6" class="my-2">
            <v-card
              class="pa-2 pb-4 main-card v-card-tile"
              rounded="lg"
              elevation="0"
            >
              <v-row no-gutters>
                <v-icon>mdi-account-circle</v-icon
                ><label class="pt-1 ps-1 m-0 text-color">{{
                  $t("profile_Status")
                }}</label>
              </v-row>
              <v-row>
                <v-col cols="12" md="6" lg="6" class="d-flex justify-center">
                  <v-progress-circular
                    :rotate="360"
                    :size="200"
                    :width="25"
                    :value="profile_status"
                    color="green"
                    class="text-h5"
                  >
                    {{ profile_status }}{{ "%" }}
                  </v-progress-circular></v-col
                >
                <v-col cols="12" md="6" lg="6" class="d-flex justify-center">
                  <v-list nav dense>
                    <v-list-item class="d-flex justify-center">
                      <v-list-item-icon class="me-4">
                        <v-btn
                          class="white--text elevation-0"
                          x-small
                          fab
                          :color="profileData.personal_info ? 'green' : 'red'"
                        >
                          <v-icon>{{
                            profileData.personal_info
                              ? "mdi-checkbox-marked-circle"
                              : "mdi-checkbox-blank-circle-outline"
                          }}</v-icon>
                        </v-btn>
                      </v-list-item-icon>
                      <v-list-item-title color="secondary" class="mt-2">{{
                        $t("personalInfo")
                      }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-icon class="me-4">
                        <v-btn
                          class="white--text elevation-0"
                          x-small
                          fab
                          :color="profileData.contact_info ? 'green' : 'red'"
                        >
                          <v-icon>{{
                            profileData.contact_info
                              ? "mdi-checkbox-marked-circle"
                              : "mdi-checkbox-blank-circle-outline"
                          }}</v-icon>
                        </v-btn>
                      </v-list-item-icon>
                      <v-list-item-title color="secondary" class="mt-2">{{
                        $t("contactInfo")
                      }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-icon class="me-4">
                        <v-btn
                          class="white--text elevation-0"
                          x-small
                          fab
                          :color="profileData.documents ? 'green' : 'red'"
                        >
                          <v-icon>{{
                            profileData.documents
                              ? "mdi-checkbox-marked-circle"
                              : "mdi-checkbox-blank-circle-outline"
                          }}</v-icon>
                        </v-btn>
                      </v-list-item-icon>
                      <v-list-item-title color="secondary" class="mt-2">{{
                        $t("uploadingDoc")
                      }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-icon class="me-4">
                        <v-btn
                          class="white--text elevation-0"
                          x-small
                          fab
                          :color="profileData.kyc ? 'green' : 'red'"
                        >
                          <v-icon>{{
                            profileData.kyc
                              ? "mdi-checkbox-marked-circle"
                              : "mdi-checkbox-blank-circle-outline"
                          }}</v-icon>
                        </v-btn>
                      </v-list-item-icon>
                      <v-list-item-title color="secondary" class="mt-2">{{
                        $t("kycApproval")
                      }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="12" md="6" lg="6" class="my-2">
            <v-card
              class="pa-2 pb-4 main-card v-card-tile h-100"
              rounded="lg"
              elevation="0"
            >
              <v-row no-gutters>
                <v-icon color="#6d6d6d">mdi-lock</v-icon
                ><label class="pt-1 ps-1 m-0 text-color">{{
                  $t("security&verification")
                }}</label>
              </v-row>
              <v-row>
                <v-col cols="12" md="6" lg="6" class="d-flex justify-center">
                  <v-progress-circular
                    :rotate="360"
                    :size="200"
                    :width="25"
                    :value="security_status"
                    color="green"
                    class="text-h5"
                  >
                    {{ security_status }}{{ "%" }}
                  </v-progress-circular></v-col
                >
                <v-col cols="12" md="6" lg="6" class="d-flex justify-center">
                  <v-list nav dense>
                    <v-list-item class="d-flex justify-center">
                      <v-list-item-icon class="me-4">
                        <v-btn
                          class="white--text elevation-0"
                          x-small
                          fab
                          color="green"
                        >
                          <v-icon> mdi-checkbox-marked-circle</v-icon>
                        </v-btn>
                      </v-list-item-icon>
                      <v-list-item-title color="secondary" class="mt-2">{{
                        $t("2fa")
                      }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-icon class="me-4">
                        <v-btn
                          class="white--text elevation-0"
                          x-small
                          fab
                          color="green"
                        >
                          <v-icon> mdi-checkbox-marked-circle</v-icon>
                        </v-btn>
                      </v-list-item-icon>
                      <v-list-item-title color="secondary" class="mt-2">
                        {{ $t("256bit") }}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-icon class="me-4">
                        <v-btn
                          class="white--text elevation-0"
                          x-small
                          fab
                          color="green"
                        >
                          <v-icon> mdi-checkbox-marked-circle</v-icon>
                        </v-btn>
                      </v-list-item-icon>
                      <v-list-item-title color="secondary" class="mt-2">
                        {{ $t("mobile") }}</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-icon class="me-4">
                        <v-btn
                          class="white--text elevation-0"
                          x-small
                          fab
                          :color="email_confirmed ? 'green' : 'orange'"
                        >
                          <v-icon>{{
                            email_confirmed
                              ? "mdi-checkbox-marked-circle"
                              : "mdi-checkbox-blank-circle-outline"
                          }}</v-icon>
                        </v-btn>
                      </v-list-item-icon>
                      <v-list-item-title color="secondary" class="mt-2">{{
                        $t("email_home")
                      }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-row justify="center">
        <v-dialog v-model="isNotify" persistent max-width="400" elevation="1">
          <v-card>
            <v-card-title class="color-primary font-weight-bold h3 pa-2 m-0">
              {{ $t("qrate") }}
            </v-card-title>
            <v-divider class="p-0 m-0"></v-divider>
            <v-card-text class="pa-5 text-h6">
              {{ showNotificationMsg }}
            </v-card-text>
            <v-divider class="p-0 m-0"></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="closeNotify">
                Continue
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import axios from "axios";
import TitleBar from "@/components/navigations/TitleBar.vue";
import { mobilebreakdown, webbreakdown } from "../utils/breakpoints";
import transactionService from "@/services/TransactionService/transactionService.js";
export default {
  name: "HomeView",
  components: {
    Loading,
    TitleBar,
  },
  data() {
    return {
      loader: false,
      mobilebreakdown,
      webbreakdown,
      navigatetoPage: "",
      isNotify: false,
      email_confirmed: false,
      showNotificationMsg: "",
      profile_status: 0,
      security_status: 75,
      profileData: {},
      balance: {},
      isRecentTrans: false,
      no_of_days: 30,
      isKey: 1,
      recentTransaction: [],
      headers: [
        {
          text: "Transaction Pin",
          align: "start",
          sortable: false,
          value: "transaction_pin",
        },
        { text: "Status", value: "status" },
        { text: "Payment Mode", value: "payment_mode" },
        { text: "Amount($)", value: "amount" },
        { text: "Date", value: "created_date" },
      ],
      dashDetails: {},
    };
  },
  created() {
    this.GetNotification();
  },
  watch: {
    mobilebreakdown(newval) {
      // alert(newval);
      console.log(newval);
    },
  },
  mounted() {
    this.getProfile();
    this.getDashboardDetails();
    this.getRecentTransaction();
    this.getBalance();
  },
  methods: {
    getColor(status) {
      if (status == "Paid") return "green";
      else if (status == "Pending") return "orange";
      else if (status == "Hold") return "yellow";
      else if (status == "Rejected") return "red";
      else return "blue";
    },
    returntxnType(item) {
      if (item.txn_type === "I") {
        return "+";
      } else if (item.txn_type == "O") {
        return "-";
      }
    },
    returnTextCol(item) {
      if (item.txn_type === "I") {
        return "success--text";
      } else if (item.txn_type == "O") {
        return "red--text";
      }
    },
    async getBalance() {
      try {
        const response = await transactionService.getBalance();
        if (response.data && response.data.status_code === 200) {
          console.log("response.data.data[0]", response.data.data[0]);
          this.balance = response.data.data[0];
        }
      } catch (error) {
        this.balance = {};
        console.log(error);
      }
    },
    getProfile() {
      this.$store
        .dispatch("Authentication/getProfile")
        .then((res) => {
          if (Array.isArray(res.data.data)) {
            this.profiledata = res.data.data;
            if (this.profiledata[0].is_email_confirmation) {
              this.security_status = 100;
              this.email_confirmed = true;
            } else {
              this.security_status = 75;
              this.email_confirmed = false;
            }
          }
        })
        .catch((err) => console.error(err));
    },

    closeNotify() {
      console.log(this.navigatetoPage);
      this.isNotify = false;
      this.$router.push({ name: this.navigatetoPage });
      this.updateNotification();
    },
    async GetNotification() {
      const user_type = sessionStorage.getItem("user_type");
      let responseData = await this.$store.dispatch(
        "Transactional/getNotificationStatus"
      );
      let statusDetails = await this.$store.dispatch(
        "Transactional/getProfileData"
      );
      console.log("statusDetails", statusDetails.data[0]);
      let kyc = statusDetails.data[0].kyc;
      let registered = statusDetails.data[0].personal_info;
      console.log("kyc", kyc);
      console.log("registered", registered);
      let notification = responseData.data[0].notification;
      sessionStorage.setItem("ActiveNotify", notification);
      if (!registered && notification) {
        this.isNotify = true;
        this.showNotificationMsg =
          "Seems you are not yet registered with us.Please complete the registration process by clicking 'Continue'.";

        if (user_type === "BUS") {
          this.navigatetoPage = "BusUserInfo";
        } else {
          this.navigatetoPage = "CreateUserMain";
        }
      }
      if (registered && !kyc && notification) {
        this.isNotify = true;
        this.showNotificationMsg =
          "Seems you are not yet completed the KYC process.Please complete the KYC by clicking 'Continue'.";
        this.navigatetoPage = "KYC";
      }
    },

    async getDashboardDetails() {
      this.loader = true;
      let responseData = await Promise.all([
        this.$store.dispatch("Transactional/getProfileData"),
      ]);
      console.log("dashDetails", responseData[0].status_code);
      if (responseData[0].status_code === 200) {
        this.loader = false;
        this.profileData = responseData[0].data[0];
        console.log("dashDetails", this.dashDetails);
        console.log("profileData", this.profileData);
        let value = 0;
        for (let status in this.profileData) {
          if (this.profileData[`${status}`]) {
            value += 1;
            this.profile_status = value * 25;
          }
        }
      }
      this.loader = false;
    },
    handleViewMore() {
      this.$router.push("/transactionSummary");
    },
    handleRepeatTransaction(id) {
      this.$router.push(`/transaction/${id}`);
    },
    async getRecentTransaction() {
      this.loader = true;
      try {
        const response = await transactionService.getBalanceHistory({
          skip: 0,
          limit: 300000,
        });
        if (response.data && response.data.status_code === 200) {
          this.loader = false;
          console.log("response.data.data", response.data.data);
          if (response.data.data && response.data.data.length > 4) {
            this.recentTransaction = response.data.data.slice(0, 4);
          } else {
            this.recentTransaction = response.data.data;
          }
        } else {
          this.loader = false;
        }
      } catch (error) {
        this.loader = false;
        console.log(error);
      }
    },
    async updateNotification() {
      const token = sessionStorage.getItem("access_token");
      try {
        let responseData = await axios.put(
          `${process.env.VUE_APP_USER_SERVICE}/api/v1/user/update-notification?notification=false`,
          {},
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(responseData);
        const notification = responseData.data[0].notification;
        sessionStorage.setItem("ActiveNotify", notification);
      } catch (e) {
        return e;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/global.scss";

.label-text {
  font-size: 10px !important;
}

.v-auto-complete {
  max-width: 50px !important;
  font-size: 12px !important;
  font-weight: bold !important;
  color: $lightgrey !important;
}
.list-template {
  border-top: 1px solid $lightgrey;
  padding: 0.2rem 1rem;
}
.v-text-field__details {
  height: 0px !important;
}
.border-grey {
  border: 2px solid $gainsboro;
}
.normal-flag {
  margin: 0px;
}
.v-text-field {
  padding-top: 0px;
  margin: 0px 6px;
}
.v-grap-v-btn {
  box-shadow: none !important;
  background-color: transparent !important;
  min-width: 20px !important ;
  margin: 3px;
}
.v-grap-text {
  font-size: 14px !important;
  color: $lightgrey !important;
}

.v-home-subcard-2 {
  background-color: rgb(52, 52, 192) !important;
  padding: 20px;
  color: #efefef !important;
  min-height: 120px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.v-home-subcard-header-2 {
  background-color: rgb(52, 52, 192) !important;
  padding: 2px;
  color: #efefef !important;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 5px !important;
  text-align: center;
}
.v-home-subcard-main {
  border: 1px solid rgb(52, 52, 192);
  padding-bottom: 10px;
}

.v-home-subcard-header-1 {
  background-color: #12be57 !important;
  padding: 2px;
  color: #efefef !important;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 5px !important;
  text-align: center;
}
.v-home-subcard-main-1 {
  border: 1px solid #12be57;
  padding-bottom: 10px;
  flex-direction: row;
}
.v-home-subcard-1 {
  background-color: #12be57 !important;
  padding: 20px;
  color: #efefef !important;
  text-align: center;
}
.v-sheet--offset {
  top: -15px;
  width: 80px;
  height: 80px;
  position: relative;
}
.text-color {
  color: #6d6d6d;
}
.font-size {
  font-size: 14px;
}
.anchor {
  color: orange;
}
</style>
